import React, { Component, Fragment  } from "react";
//import { Router,  Route, Switch } from "react-router";
import { BrowserRouter,  Route, Switch } from "react-router-dom";
import HomePage from "../pages/HomePage";

class AppRoute extends Component {
	render() {
		return (
			<Fragment>
				<BrowserRouter>
				<Switch>
					<Route exact to="/" component={HomePage} />
				</Switch>
				</BrowserRouter>
			</Fragment>
		)
	}
}

export default AppRoute