import React, { Component, Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class NewArrival extends Component {
	
	constructor(props) {
		super(props);
		this.next=this.next.bind(this);
		this.previous=this.previous.bind(this);
	}
	
	next() {
		this.slider.slickNext();
	}
	
	previous() {
		this.slider.slickPrev();
	}
	
	render() {
		var settings = {
			dots: false,
			infinite: true,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 3000,
			slidesToShow: 5,
			slidesToScroll: 1,
			initialSlide: 0,
			arrows: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						initialSlide: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		}
		
		
		return (
			<Fragment>
				<Container>
					<div className="section-title text-center mb-55" fluid={true}>
						<h2>
							New Arrivals &nbsp;
							<a className="btn btn-sm ml-2 site-btn" onClick={this.previous}><i className="fa fa-angle-left"></i> </a>
							&nbsp;
							<a className="btn btn-sm ml-2 site-btn" onClick={this.next}><i className="fa fa-angle-right"></i> </a>
						
						</h2>
						<p>Our New Arrivals</p>
					</div>
					<Row>
						<Slider ref={c=>(this.slider=c)} {...settings}>
							<div>
								<Card className="image-box card">
									<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
									<Card.Body>
										<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
										<p className="product-price-on-card">Price: €189</p>
									</Card.Body>
								</Card>
							</div>
							<div>
								<Card className="image-box card">
									<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
									<Card.Body>
										<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
										<p className="product-price-on-card">Price: €189</p>
									</Card.Body>
								</Card>
							</div>
							<div>
								<Card className="image-box card">
									<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
									<Card.Body>
										<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
										<p className="product-price-on-card">Price: €189</p>
									</Card.Body>
								</Card>
							</div>
							<div>
								<Card className="image-box card">
									<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
									<Card.Body>
										<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
										<p className="product-price-on-card">Price: €189</p>
									</Card.Body>
								</Card>
							</div>
							<div>
								<Card className="image-box card">
									<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
									<Card.Body>
										<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
										<p className="product-price-on-card">Price: €189</p>
									</Card.Body>
								</Card>
							</div>
							<div>
								<Card className="image-box card">
									<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
									<Card.Body>
										<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
										<p className="product-price-on-card">Price: €189</p>
									</Card.Body>
								</Card>
							</div>
							<div>
								<Card className="image-box card">
									<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
									<Card.Body>
										<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
										<p className="product-price-on-card">Price: €189</p>
									</Card.Body>
								</Card>
							</div>
							<div>
								<Card className="image-box card">
									<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
									<Card.Body>
										<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
										<p className="product-price-on-card">Price: €189</p>
									</Card.Body>
								</Card>
							</div>
						</Slider>
					</Row>
				</Container>
			</Fragment>
		)
	}
}

export default NewArrival