import React, { Component } from "react";

class MegaMenuAll extends Component {
	
	constructor() {
		super();
		this.MegaMenu = this.MegaMenu.bind(this);
	}
	
	componentDidMount() {
		this.MegaMenu();
	}
	
	MegaMenu() {
		window.onload = function () {
			var acc = document.getElementsByClassName("accordionAll");
			var accNum = acc.length;
			
			var i;
			for(i=0; i<accNum; i++) {
				//console.log(acc[i]);
				acc[i].addEventListener("click", function () {
					this.classList.toggle("active");
					var panel = this.nextElementSibling;
					if (panel.style.maxHeight) {
						panel.style.maxHeight = null;
					} else {
						panel.style.maxHeight = panel.scrollHeight+ "px";
					}
				})
			}
		}
		
	}
	
	
	render() {
		return (
			<div className="accordionMenuDivAll">
				<div className="accordionMenuDivInsideAll">
					<button className="accordionAll">
						<img className="accordionMenuIconAll" src="https://cdn-share-sprites.flaticon.com/pack/3/3884/3884251-home-screen-apps_3x2.jpg" />&nbsp; Women's Clothing
					</button>
					<div className="panelAll">
						<ul>
							<li>
								<a href="#" className="accordionItemAll">Women Hat</a>
							</li>
							<li>
								<a href="#" className="accordionItemAll">Women Shoes</a>
							</li>
						</ul>
					</div>
					
					
				
				</div>
			</div>
		)
	}
}

export default MegaMenuAll