import React, { Component, Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";


class Categories extends Component {
	render() {
		return (
			<Fragment>
				<Container>
					<div className="section-title text-center mb-55" fluid={true}>
						<h2>Categories</h2>
						<p>Browse our categories</p>
					</div>
					<Row>
						<Col key={1} xl={6} lg={6} md={2} sm={12} xs={12}>
							<Row>
								<Col className="p-0"  key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
									<Card className="h-100 w-100 text-center">
										<Card.Body>
											<img className="center" src="https://rukminim2.flixcart.com/flap/64/64/image/22fddf3c7da4c4f4.png?q=100" />
											<h5 className="category-name">Mobile</h5>
										</Card.Body>
									</Card>
								</Col>
								
								<Col className="p-0"  key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
									<Card className="h-100 w-100 text-center">
										<Card.Body>
											<img className="center" src="https://rukminim2.flixcart.com/flap/64/64/image/29327f40e9c4d26b.png?q=100" />
											<h5 className="category-name">Grocery</h5>
										</Card.Body>
									</Card>
								</Col>
								
								<Col className="p-0"  key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
									<Card className="h-100 w-100 text-center">
										<Card.Body>
											<img className="center" src="https://rukminim2.flixcart.com/flap/64/64/image/22fddf3c7da4c4f4.png?q=100" />
											<h5 className="category-name">Fashion</h5>
										</Card.Body>
									</Card>
								</Col>
								
								<Col className="p-0"  key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
									<Card className="h-100 w-100 text-center">
										<Card.Body>
											<img className="center" src="https://rukminim2.flixcart.com/fk-p-flap/64/64/image/0139228b2f7eb413.jpg?q=100" />
											<h5 className="category-name">Electronics</h5>
										</Card.Body>
									</Card>
								</Col>
								
							</Row>
							
						</Col>
						
						<Col key={1} xl={6} lg={6} md={2} sm={12} xs={12}>
							<Row>
								<Col className="p-0"  key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
									<Card className="h-100 w-100 text-center">
										<Card.Body>
											<img className="center" src="https://rukminim2.flixcart.com/fk-p-flap/64/64/image/0139228b2f7eb413.jpg?q=100"/>
											<h5 className="category-name">Appliances</h5>
										</Card.Body>
									</Card>
								</Col>
								
								<Col className="p-0"  key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
									<Card className="h-100 w-100 text-center">
										<Card.Body>
											<img className="center" src="https://rukminim2.flixcart.com/flap/64/64/image/22fddf3c7da4c4f4.png?q=100" />
											<h5 className="category-name">Travel</h5>
										</Card.Body>
									</Card>
								</Col>
								
								<Col className="p-0"  key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
									<Card className="h-100 w-100 text-center">
										<Card.Body>
											<img className="center" src="https://rukminim2.flixcart.com/flap/64/64/image/22fddf3c7da4c4f4.png?q=100" />
											<h5 className="category-name">Toys</h5>
										</Card.Body>
									</Card>
								</Col>
								
								<Col className="p-0"  key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
									<Card className="h-100 w-100 text-center">
										<Card.Body>
											<img className="center" src="https://rukminim2.flixcart.com/flap/64/64/image/22fddf3c7da4c4f4.png?q=100" />
											<h5 className="category-name">Beauty</h5>
										</Card.Body>
									</Card>
								</Col>
							
							</Row>
						
						</Col>
						
						
					</Row>
				</Container>
			</Fragment>
		)
	}
}

export default Categories