import React, { Component, Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";


class Collection extends Component {
	render() {
		return (
			<Fragment>
				<Container>
					<div className="section-title text-center mb-55" fluid={true}>
						<h2> PRODUCT Collection</h2>
						<p>Our Special product collections</p>
					</div>
					<Row>
						<Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6}>
							<Card className="image-box card w-100">
								<img className="center w-75" src="https://rukminim2.flixcart.com/image/612/612/kx25ksw0/shirt/o/g/p/xxl-men-slim-regular-fit-mandarin-chinese-collar-full-sleeve-original-imag9hb74a7grhzg.jpeg?q=70" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6}>
							<Card className="image-box card w-100">
								<img className="center w-75" src="https://rukminim2.flixcart.com/image/612/612/kx25ksw0/shirt/o/g/p/xxl-men-slim-regular-fit-mandarin-chinese-collar-full-sleeve-original-imag9hb74a7grhzg.jpeg?q=70" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6}>
							<Card className="image-box card w-100">
								<img className="center w-75" src="https://rukminim2.flixcart.com/image/612/612/kx25ksw0/shirt/o/g/p/xxl-men-slim-regular-fit-mandarin-chinese-collar-full-sleeve-original-imag9hb74a7grhzg.jpeg?q=70" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6}>
							<Card className="image-box card w-100">
								<img className="center w-75" src="https://rukminim2.flixcart.com/image/612/612/kx25ksw0/shirt/o/g/p/xxl-men-slim-regular-fit-mandarin-chinese-collar-full-sleeve-original-imag9hb74a7grhzg.jpeg?q=70" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6}>
							<Card className="image-box card w-100">
								<img className="center w-75" src="https://rukminim2.flixcart.com/image/612/612/kx25ksw0/shirt/o/g/p/xxl-men-slim-regular-fit-mandarin-chinese-collar-full-sleeve-original-imag9hb74a7grhzg.jpeg?q=70" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6}>
							<Card className="image-box card w-100">
								<img className="center w-75" src="https://rukminim2.flixcart.com/image/612/612/kx25ksw0/shirt/o/g/p/xxl-men-slim-regular-fit-mandarin-chinese-collar-full-sleeve-original-imag9hb74a7grhzg.jpeg?q=70" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6}>
							<Card className="image-box card w-100">
								<img className="center w-75" src="https://rukminim2.flixcart.com/image/612/612/kx25ksw0/shirt/o/g/p/xxl-men-slim-regular-fit-mandarin-chinese-collar-full-sleeve-original-imag9hb74a7grhzg.jpeg?q=70" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6}>
							<Card className="image-box card w-100">
								<img className="center w-75" src="https://rukminim2.flixcart.com/image/612/612/kx25ksw0/shirt/o/g/p/xxl-men-slim-regular-fit-mandarin-chinese-collar-full-sleeve-original-imag9hb74a7grhzg.jpeg?q=70" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Fragment>
		)
	}
}

export default Collection