import React, { Component, Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";


class FeaturedProducts extends Component {
	render() {
		return (
			<Fragment>
				<Container>
					<div className="section-title text-center mb-55" fluid={true}>
						<h2>FEATURED PRODUCT</h2>
						<p>Some Special that you want to purchase</p>
					</div>
					<Row>
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/b/i/x/-original-imagt4qptrkzwmxa.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">realme C51 (Mint Green, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €289</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/d/3/g/-original-imagy2v5ggthbvfe.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">POCO M6 5G (Polaris Green, 256 GB)  (8 GB RAM)</p>
									<p className="product-price-on-card">Price: €320</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/b/i/x/-original-imagt4qptrkzwmxa.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">realme C51 (Mint Green, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €289</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/d/3/g/-original-imagy2v5ggthbvfe.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">POCO M6 5G (Polaris Green, 256 GB)  (8 GB RAM)</p>
									<p className="product-price-on-card">Price: €320</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/b/i/x/-original-imagt4qptrkzwmxa.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">realme C51 (Mint Green, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €289</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/b/i/x/-original-imagt4qptrkzwmxa.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">realme C51 (Mint Green, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €289</p>
								</Card.Body>
							</Card>
						</Col>
						
						<Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
							<Card className="image-box card">
								<img className="center" src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/9/i/h/-original-imagt4tdvvhyag9j.jpeg?q=70&crop=false" />
								<Card.Body>
									<p className="product-name-on-card">POCO C51 - Locked with Airtel Prepaid (Royal Blue, 64 GB)  (4 GB RAM)</p>
									<p className="product-price-on-card">Price: €189</p>
								</Card.Body>
							</Card>
						</Col>
						
					</Row>
				</Container>
			</Fragment>
		)
	}
}

export default FeaturedProducts