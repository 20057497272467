import React, { Component, Fragment } from "react";
import FeaturedProducts from "../components/home/FeaturedProducts";
import Categories from "../components/home/Categories";
import Collection from "../components/home/Collection";
import NewArrival from "../components/home/NewArrival";
import TopSection from "../components/home/TopSection";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";
import TopSectionMobile from "../components/home/TopSectionMobile";

class HomePage extends Component {
	render() {
		return (
			<Fragment>
				<div className="Desktop">
					<NavMenuDesktop/>
					<TopSection/>
				</div>
				<div className="Mobile">
					<NavMenuMobile/>
					<TopSectionMobile/>
				</div>
				<FeaturedProducts />
				<NewArrival/>
				<Categories/>
				<Collection/>
				
			</Fragment>
		)
	}
}

export default HomePage